<template>
  <v-container fluid>
    <v-data-table
      class="pt-3"
      flat
      :loading="loading ? '#144881' : null"
      :headers="headers"
      :search="searchData"
      :items="Semesters"
      :items-per-page="5"
      sort-by=""
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title
            >Liste des <span class="font-weight-bold">semestres</span> de
            l'année scolaire {{ SchoolYear.name }}</v-toolbar-title
          >

          <v-spacer></v-spacer>
          <template>
            <v-btn
              color="#144881"
              dark
              class="mb-2 mr-8"
              @click="addItem"
              v-bind="attrs"
              outlined
              pill
              v-on="on"
              rounded
            >
              <v-icon left> mdi-account-outline </v-icon>
              Ajout
            </v-btn>
          </template>
        </v-toolbar>
        <v-container fluid>
          <v-expansion-panels flat style="border: 1px solid #144881">
            <v-expansion-panel class="elevation-0 pa-0">
              <v-expansion-panel-header>
                Tri / Recherche ...
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12" md="12">
                  <v-text-field
                    color="#144881"
                    label="Recherche "
                    class="pt-5 pa-2"
                    filled
                    prepend-inner-icon="mdi-magnify"
                    dense
                    v-model="searchData"
                    outlined
                    flat
                    background-color="grey lighten-4"
                    rounded
                  ></v-text-field>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-menu elevation="0" right offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="mr-md-10">
              <v-icon color="success" size="30">mdi-forwardburger</v-icon>
            </v-btn>
          </template>
          <v-list>
          
            <v-tooltip bottom color="warning">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-3" icon>
                  <v-icon
                    size="20"
                    @click="updateItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    color="warning"
                    >mdi-pencil-box-multiple-outline</v-icon
                  >
                </v-btn>
              </template>
              <span>Modifier</span>
            </v-tooltip>
            <v-tooltip bottom color="red">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-3" icon>
                  <v-icon
                    size="20"
                    @click="deleteItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
              <span>Supprimer</span>
            </v-tooltip>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-dialog v-model="editorDialog" max-width="1000px" class="black">
      <editor-x
        :fields="fields"
        :model="Model"
        :callback="callBack"
        @success="successEditor"
        :title="
          Model && Model._id
            ? `Modification d'une année scolaire`
            : `Ajout d'une année scolaire`
        "
        :actionBtnLabel="Model && Model._id ? `Modifier` : `Ajouter`"
      />
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="1000px" class="black">
      <delete-dialog
        :id="Model && Model._id ? Model._id : ''"
        :callback="callBack"
        @success="successEditor"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { defaultMethods, messages } from "../../utils/data";
import { addSemester, updateSemester, deleteSemester } from "../../api/user";
import { semestersNumber } from "../../api/universal";
import EditorX from "../../components/universal/EditorX.vue";
import DeleteDialog from "../../components/universal/DeleteDialog.vue";
export default {
  name: "SchoolYear",
  components: {
    EditorX,
    DeleteDialog,
  },
  data: () => ({
    callBack: "",
    searchData: "",
    dialog: false,
    loading: false,
    headers: [
    {
        text: "Actions",
        width: "150px",
        align: "left",
        value: "actions",
  sortable: true,
      },
      {
        text: "Numéro",
        align: "start",
  sortable: true,
        value: "name",
        width: "200px",
      },
     
    ],
    editedIndex: -1,
    Model: {},
    defaultItem: {},
    editorDialog: false,
    deleteDialog: false,
  }),
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters(["Semesters", "SchoolYear"]),

    fields() {
      return [
        {
          name: "name",
          label: "Semestre numéro",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            data: semestersNumber(),
            multiple: false,
          },
        },
        {
          name: "schoolYear",
          label: "",
          type: "Static",
          value: this.$route.params.id,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
      ];
    },
  },

  watch: {},
  methods: {
    ...mapActions(["getSemesters"]),
    closeForm() {
      this.Model = {};
      this.editorDialog = false;
      this.editedIndex = -1;
    },

    async init() {
      this.loading = true;
      try {
        await this.getSemesters({ schoolYear: this.$route.params.id });
      } catch (error) {
        defaultMethods.dispatchError(
          this.$store,
          messages.failedToLoad("les années")
        );
      }
      this.loading = false;
    },
    successEditor() {
      this.editorDialog = false;
      this.deleteDialog = false;
      this.init();
    },
    addItem() {
      this.editedIndex = -1;
      this.Model = {};
      this.callBack = addSemester;
      this.editorDialog = true;
    },
    updateItem(item) {
      this.editedIndex = 1;
      this.Model = Object.assign({}, item);
      this.editorDialog = true;
      this.callBack = updateSemester;
    },

    deleteItem(item) {
      this.deleteDialog = true;
      this.Model = Object.assign({}, item);
      this.callBack = deleteSemester;
    },
  },
};
</script>

<style >
</style>
